import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)


//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


const routes = [
  {
    path: '*',
    redirect: '/sing-out'
  },
  {
    path: '/sing-out',
    name: 'Sing-out',
    component: () => import('../views/sing-out/sing-out.vue')
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('../views/index/index.vue'),
	redirect: "/index/index-main",
	children: [
		{
			path: 'index-main',
			component: ()=> import('@/views/index/index-main')
		},
		{
			path: 'my-order',
			component: ()=> import('@/views/my-order/my-order')
		},
		{
			path: 'data-statistics',
			component: ()=> import('@/views/my-order/data-statistics')
		},
		{
			path: 'private-letter',
			component: ()=> import('@/views/my-news/private-letter')
		},
		{
			path: 'user-comments',
			component: ()=> import('@/views/my-news/user-comments')
		},
		{
			path: 'maintenance-record',
			component: ()=> import('@/views/maintenance-record/maintenance-record')
		},
		{
			path: 'record-detaile',
			component: ()=> import('@/views/maintenance-record/record-detaile')
		},
		{
			path: 'consulting-experts',
			component: ()=> import('@/views/consulting-experts/consulting-experts')
		},
		{
			path: 'consulting-records',
			component: ()=> import('@/views/consulting-experts/consulting-records')
		},
		{
			path: 'consulting-entry',
			component: ()=> import('@/views/consulting-experts/consulting-entry')
		},
		{
			path: 'consulting-detaile',
			component: ()=> import('@/views/consulting-experts/consulting-detaile')
		},
		{
			path: 'customer-management',
			component: ()=> import('@/views/permission-setting/customer-management')
		},
		{
			path: 'add-customer',
			component: ()=> import('@/views/permission-setting/add-customer')
		},
		{
			path: 'edit-customer',
			component: ()=> import('@/views/permission-setting/edit-customer')
		},
		{
			path: 'personnel-management',
			component: ()=> import('@/views/permission-setting/personnel-management')
		},
		{
			path: 'add-employee',
			component: ()=> import('@/views/permission-setting/add-employee')
		},
		{
			path: 'edit-employee',
			component: ()=> import('@/views/permission-setting/edit-employee')
		},
		{
			path: 'essential-information',
			component: ()=> import('@/views/essential-information/essential-information')
		},
		{
			path: 'essential-information2',
			component: ()=> import('@/views/essential-information/essential-information2')
		},
		{
			path: 'set-out',
			component: ()=> import('@/views/set-out/set-out')
		},
		{
			path: 'change-password',
			component: ()=> import('@/views/set-out/change-password')
		},
		{
			path: 'index-ordedr-list',
			name:'index-ordedr-list',
			component: ()=> import('@/views/index/index-ordedr-list')
		},
		{
			path: 'order-detaile',
			component: ()=> import('@/views/my-order/order-detaile')
		},
		{
			path: 'add-order',
			component: ()=> import('@/views/my-order/add-order')
		},
		{
			path: 'index-order-detaile',
			component: ()=> import('@/views/index/index-order-detaile')
		}
	]
  }
]

const router = new VueRouter({
  routes
})

export default router
